import logo from './logo.svg';
import './App.css';
import { Routes, Route, } from "react-router-dom";
import RegisterForm from './RegisterForm';
import ReportPage from './ReportPage';
import GroupReportPage from './GroupReportPage';
import EditGroup from './EditGroup';
import AuthState from './context/auth/AuthState';
import {
  Elements
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import Success from './Success';
import SendVerification from './SendVerification';
import HomePage from './HomePage';

const stripe = loadStripe(
  "pk_live_51P0ozLRrPWcHAVCnox1yolkjrKkYHP8H9v0SjgFHu419lWnmL3ycmW1NBZlxyAwLBCF4CGb1VqEjRtl8b8M2urFX009qRkupiD"
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register-onrequest" element={<SendVerification />} />
      <Route path="/retreat-registration" element={<Elements stripe={stripe}><RegisterForm /></Elements>} />
      <Route path="/success" element={<Success />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/group-report" element={<GroupReportPage />} />
      <Route path="/edit-group/:id" element={<EditGroup />} />
    </Routes>
  );
}

export default App;
